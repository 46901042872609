.size-a4 {
  max-width: 6.2in;
  max-height: 8.7in;
}


.pdf-page {
  position:  relative;
  margin:  0;
  padding:  .1in  .1in;
  color:  #333;
  background-color:  #fff;
  box-shadow:  0  5px  10px  0  rgba(0,0,0,.3);
  box-sizing:  border-box;
  font-family:  "DejaVu Sans", "Arial", sans-serif;
}

@font-face {
  font-family:  "DejaVu Sans";
  src:  url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
}

@font-face {
  font-family:  "DejaVu Sans";
  font-weight:  bold;
  src:  url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family:  "DejaVu Sans";
  font-style:  italic;
  src:  url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}

@font-face {
  font-family:  "DejaVu Sans";
  font-weight:  bold;
  font-style:  italic;
  src:  url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}

.point {
  text-overflow: "ellipsis"
}
